import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/Seo';

const NotFoundPage = () => (
    <Layout>
        <SEO title="Ошибка 404" />
        <h2>Ошибка 404. Нет такой страницы</h2>
        <a href="/">Перейти на главную страницу</a>
    </Layout>
);

export default NotFoundPage;
